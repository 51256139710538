.admin-dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: calc(100vh - 65px);
  height: 100%;
  padding: 65px 0 20px;
  background-color: #f4f7f6;
}

h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.error-message {
  color: #e74c3c;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Styles for Tabs */
.admin-dashboard__tabs {
  background-color: #fff;
  display: flex;
  justify-content: space-between; /* Distribute tabs across full width */
  padding-top: 20px;
  margin-bottom: 20px;
  width: 100%; /* Make tabs container take the full width */
}

.admin-dashboard__tab {
  flex: 1; /* Each tab will take equal width */
  text-align: center; /* Center text within each tab */
  background-color: #ffff; /* Light background like browser tab */
  color: #333; /* Dark text */
  padding: 10px 0; /* Adjusted padding for full-width tabs */
  border: 1px solid #ccc; /* Light border around tab */
  border-bottom: none; /* Remove the bottom border to connect with content */
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  margin: 0;
  border-top-left-radius: 2% 50px;
  border-top-right-radius: 2% 50px;
  transition: background-color 0.3s ease;
  margin-right: -1px; /* Overlap borders slightly for seamless connection */
  margin-top: 0;
  /* border-bottom: 1px solid #ccc;  */
}

.admin-dashboard__tab:last-child {
  margin-right: 0; /* Remove margin for the last tab */
}

.admin-dashboard__tab.active {
  background-color: #f4f7f6; /* White background for active tab */
  color: #2B2B2B; /* Darker text for active tab */
  z-index: 1; /* Bring active tab in front of others */
}

.admin-dashboard__tab:hover {
  background-color: #f4f7f6; /* Light hover effect */
}

.admin-dashboard__tab:focus {
  outline: none;
}

.admin-dashboard__table {
  max-width: 500px;
  border-collapse: collapse;
  font-size: 16px;
  box-shadow: 0 2px 15px rgba(64, 64, 64, 0.1);
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  background-color: #fff;
  border-top: 1px solid white;
  border-left: 1px solid white;
  border-right: 1px solid white;
}

.admin-dashboard__table thead {
  background-color: #2B2B2B;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
}

.admin-dashboard__table th,
.admin-dashboard__table td {
  padding: 12px 15px;
  text-align: center;
  width: 100%;
  margin: 0;
}

.admin-dashboard__table th {
  border-bottom: 1px solid #ddd;
}

.admin-dashboard__table tr {
  border-bottom: 1px solid #dddddd;
}

.admin-dashboard__table tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.admin-dashboard__table tr:last-of-type {
  border-bottom: 2px solid #2B2B2B;
}

.admin-dashboard__table tbody tr:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

.admin-dashboard__button {
  background-color: #e74c3c;
  border: none;
  color: white;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.reservation__table {
  width: 100%;
  padding: 10px;
  border-top: 3px solid #2B2B2B;
  border-bottom: 3px solid #2B2B2B;
  border-right: 3px solid #2B2B2B;
}


.reservation-details {
  display: table-row; /* Ensures it behaves like a row */
  width: 100%;
  padding: 10px; /* Optional for spacing */
  box-sizing: border-box; /* Ensures padding does not affect width */
  text-align: start;
}

/* Optional styling to remove any gap */
.reservation__table td {
  padding: 0;
}


.admin-dashboard__arrow{
  /* margin-right: 30px; */
  border: none;
  background-color: inherit;
  cursor: pointer
}

.admin-dashboard__button:hover {
  background-color: #c0392b;
}

.admin-dashboard__button:focus {
  outline: none;
}

/* Loading and empty state styles */
.loading-message, .empty-message {
  color: #333;
  font-size: 16px;
  text-align: center;
  margin-top: 20px;
}

.admin-dashboard__title{
  text-align: center;
  margin: 0;
}

.admin-dashboard__container{
  display: flex;
  flex-direction: row;
  padding: 30px 0;
  width: 100%;
  justify-content: space-evenly;
  background-color: #2B2B2B;
}

.admin-dashboard__new-reservation {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px 0;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.admin-dashboard__new-reservation label {
  font-size: 14px;
  color: #333;
}

.admin-dashboard__new-reservation input {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

.admin-dashboard__create-button {
  background-color: #2b2b2b;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.admin-dashboard__create-button:hover {
  background-color: #555;
}

.admin-dashboard__new-reservation {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  width: 100%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

/* General form labels and inputs */
.admin-dashboard__new-reservation-label {
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
  cursor: pointer;
}

.admin-dashboard__new-reservation-select{
  /* background-color: #2B2B2B; */
  color: #2B2B2B;
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  margin-top: 10px;
  cursor: pointer;
}

.admin-dashboard__new-reservation-input{
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  background-color: #fff;
  color: #333;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.admin-dashboard__new-reservation-input:focus{
  outline: none;
  border-color: #2B2B2B;
  box-shadow: 0 0 5px rgba(43, 43, 43, 0.3);
}

.admin-dashboard__new-reservation-label{
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.admin-dashboard__table-container_reservations{
  max-height: 280px; /* Set the height you want for the table body */
  overflow-y: auto;  /* Enable vertical scrolling */
}

.admin-dashboard__radio-group {
  display: flex;
  gap: 10px;
}

.admin-dashboard__radio-group-label{
  display: flex;
  flex-direction: row;
}

.admin-dashboard__radio-group-label input{
  cursor: pointer;
}

@media screen and (max-width: 1023px) {
  .admin-dashboard__container{
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .time__calendar-container{
    margin-bottom: 50px;
  }
  .admin-dashboard__new-reservation{
    max-width: 500px;
  }

  .admin-dashboard__table-container{
    max-width: 700px;
    overflow-x: auto;
  }
}

@media screen and (max-width: 767px) {
  .admin-dashboard__new-reservation{
    max-width: 350px;
  }

  .admin-dashboard__table-container{
    max-width: 400px;
    overflow-x: auto;
  }
  .admin-dashboard__table{
    width: 100%;
  }
  .admin-dashboard__table th,
  .admin-dashboard__table td {
    padding: 20px 5px;
    text-align: start;
}
  .admin-dashboard__button{
    padding: 2px 4px;
  }

  .admin-dashboard__arrow{
  margin-right: 10px;  
  }
.admin-dashboard__new-reservation-label{
  margin-bottom: 0;
}
.admin-dashboard__option-time
.admin-dashboard__new-reservation input{
  padding: 6px;
}
}

@media screen and (max-width: 426px) {
  .admin-dashboard__new-reservation{
    max-width: 300px;
  }

  .admin-dashboard__table-container{
    width: 330px;
  }

  .admin-dashboard__table th,
  .admin-dashboard__table td {
    padding: 20px 2px;
    text-align: start;
    font-size: 12px;
}
}

@media screen and (max-width: 374px) {
  .admin-dashboard__new-reservation{
    max-width: 250px;
  }
  .admin-dashboard__table-container{
    width: 300px;
  }
}