.header{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    width: 90vw;
    padding: 0 5vw;
    height: 65px;
    /* height: calc(var(--vh, 1vh) * 10); */
    z-index: 2000;
}


.header__network{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 25%;
    margin-top: 3px;
    margin-bottom: 15px;
}

.header__linkedin-logo{
    width: 2.2vh;
    height: 2.2vh;   
    margin: 0;
    padding: 0;
}

.header__link{
    margin: 0;
    text-align: center;
}


.header__logo{
    height: 60px;
    width: 100px;
    object-fit:fill;
}

/* .header__linkedin-logo{
    width: 25px;
    height: 25px;   
} */

.header__navigation{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
    width: 60%;
}

.header__links-row{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.header__links-column{
    display: flex;
    justify-content: flex-end;
    position: fixed;
    top: 10vh;
    /* height: calc(var(--vh, 1vh) * 10); */
    height:90vh;
    /* height: calc(var(--vh, 1vh) * 90); */
    left:0;
    transform: translateY(0%);
    /* visibility: visible; */
    overflow: auto;
    z-index: 2;
    width: 100vw;
    background-color: #0000004d;
}

.header__instagram-link{
    display: none;
}

.header__instagram-logo{
    display: none;
}

.header__links-column-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* justify-content: flex-start; */
    /* align-items: center; */
    /* padding: 22px 22px 90px; */
    background-color: white;
    width:100vw;
    /* max-width: 520px;
    width: 100%; */
    min-height: 80vh;
    /* min-height: calc(var(--vh, 1vh) * 80); */
    padding-top: 30px;
    /* row-gap: 105px; */
}

.header__burger-menu{
    display: none;
}

.header__burger-close-button{
    display: none;
}

@media screen and (max-width: 1330px) {
    .header{
        width: 90vw;
        padding: 0 5vw;
    }

    /* .header__links-row{
        width: 60%;
    } */
}

@media screen and (max-width: 1200px) {
    .header{
        width: 93vw;
        padding: 0 2vw 0 5vw;
    }

    .header__navigation{
        width: 75%;
    }

    .header__network{
        margin-bottom: 5px;
    }
    
}

@media screen and (max-width: 1024px) {
.header{
    height: 65px;
    /* height: calc(var(--vh, 1vh) * 10); */
}

.header__phone{
    margin-right: 5px;
    font-size: 10px;
}

.header__instagram-link{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.header__instagram-logo{
    display: block;
    margin-right: 25px;
    margin-bottom: 0;
    width: 30px;
    height: 30px;
}

.header__network{
    margin: 0 auto;
    border: none;
}

.header__linkedin-logo{
    width: 2vh;
    height: 2vh;   
    /* margin: 0;
    padding: 0;
    margin-right: 40px; */
}

.header__navigation{
    height:min-content;
}

.header__logo{
    height: 65px;
    /* height: calc(var(--vh, 1vh) * 10); */
}
.header__links-row {
    display: none;
}
.header__links-column{
    display: flex;
    justify-content: flex-end;
    position: fixed;
    top: 65px;
    /* top: calc(var(--vh, 1vh) * 10); */
    height:90vh;
    /* height: calc(var(--vh, 1vh) * 90); */
}

.header__burger-menu{
    display: block;
    border: none;
    width: 28px;
    height: 23px;
    /* height: calc(var(--vh, 1vh) * 6);    */
    background-image: url("../../images/burger.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: white;
}

.header__burger-close-button{
    display: block;
    border: none;
    width: 28px;
    height: 23px;
    background-image: url("../../images/close-button.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: white;
}

.header__links-column-container{
    height: 100%;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    -ms-verrflow-style: none;
    overflow: - moz-scrollbars-none;
    scrollbar-width: none;
    -ms-overflow-style: none; 
    /* min-height: calc(var(--vh, 1vh) * 80); */
}

.header__network{
    display:none
}
}
