.gift-booking {
  width: 90%;
  margin: 0 auto;
  padding-top: 65px;
  height: 100%;
  margin-bottom: 50px;
}

.gift-booking__title {
  font-size: 28px;
  color: #918E8E;
  margin: 2px auto;
}

.gift-booking-control {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .gift-booking-input {
    padding: 8px 10px;
    border: 1px solid #918E8E;
    border-radius: 4px;
    width: 100px;
    height: 20px; /* Smaller height */
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 4px;
    -webkit-appearance: none; /* Remove default arrows for Safari and Chrome */
    -moz-appearance: textfield; /* Remove default arrows for Firefox */
  }
  
  /* For Chrome, Safari, and Edge */
  .gift-booking-input::-webkit-outer-spin-button,
  .gift-booking-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* For Firefox */
  .gift-booking-input[type='number'] {
    -moz-appearance: textfield; /* Remove default number input arrows */
  }
/* gift-booking Input Container */
.gift-booking__quantity-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  /* margin-bottom: 20px; */
}

.gift-booking__duration-row{
  width: 100%;
  height: 100%;
  margin-top: 20px;
  /* border: 1px solid #918E8E; */
  /* border-radius: 5px;
  background-color: #ddd; */
}

/* Quantity Button Styling */
.gift-booking__quantity-buttons {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.gift-booking__quantity-button-up,
.gift-booking__quantity-button-down {
  background-color: #918E8E;
  color: white;
  cursor: pointer;
  width: 30px;
  height: 20px;
  border: none;
  border-radius: 4px;
  margin-left: 20px ;
  /* border: 1px solid #ddd;
  background-color: #f9f9f9; */
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
}

.gift-booking__quantity-button-up:hover,
.gift-booking__quantity-button-down:hover {
  background-color: #F6C852;
  color: white;
}

  
  .gift-booking-buttons {
    display: flex;
    flex-direction: column;
    gap: 2px; /* Space between the buttons */
  }
  
  .gift-booking-button {
    width: 25px; /* Smaller width */
    height: 25px; /* Smaller height */
    background-color: #F6C852;
    border: none;
    color: white;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .gift-booking-button:hover {
    background-color: #e68a00;
  }
  
  .gift-booking__form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 50%;
    margin: 0 auto;
  }

  
  .gift-booking__form-group {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .gift-booking__form-group label {
    font-size: 16px;
    margin-bottom: 5px;
  }
  
  .gift-booking__form-group input {
    padding: 8px 10px;
    border: 1px solid #918E8E;
    border-radius: 4px;
  }

  .gift-booking__form-options {
    display: flex;
    gap: 20px; /* Spacing between Yes and No options */
    margin-top: 10px;
  }
  
  .gift-booking__form-options label {
    font-size: 16px;
    display: flex;
    align-items: center;
  }
  
  .gift-booking__radio{
    margin-right: 8px;
    cursor: pointer;
  }
  
  .gift-booking__form-field{
    display: flex;
    flex-direction: row;
  }
  
  .gift-booking__form-error{
    color: red;
  }

  .gift-booking__form-field-pets-desktop{
    margin: 0; 
    padding: 0;
  }
  .gift-booking__form-field-pets-mobile{
    display: none;
  }

    
  .gift-booking__button-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 80%;
    margin: 0 auto;
  }
  
  /* Next Step Button */
  .gift-booking__next-step {
    width: 90%;
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
    margin-right: 0px;
  }

  .gift-booking__final-step-button{
    width: 250px;
    height: 40px;
    border: 0;
    background-color: #F6C852;
    color: white;
    font-size: 18px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .gift-booking__final-step-button_active {
    background-color: #F6C852;
    color: #fff;
    cursor: pointer;
  }
  
  .gift-booking__final-step-button_active:hover {
    background-color: #F6C852;
  }
  
  .gift-booking__next-step-button {
    width: 120px;
    height: 40px;
    margin-top: 20px;
    margin-right: 0;
    padding: 10px 20px;
    font-size: 18px;
    font-weight: bold;
    background-color: #918E8E;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: not-allowed;
    transition: background-color 0.3s ease;
  }
  

  .gift-booking__next-step-button_active {
    background-color: #F6C852;
    color: #fff;
    cursor: pointer;
  }
  
  .gift-booking__next-step-button_active:hover {
    background-color: #ff9900;
  }
  .gift-booking__choice{
    margin: 50px auto 0;
  }

  .gift-booking__reservation{
    margin: 0 auto 0;
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .gift-booking__session-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width:  350px;
    padding: 0 30px;
  }

  .gift-booking__quantity-button{
    background-color: #918E8E;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-weight: bold;
    margin: 0 20px;
    color: white;
    width: 30px;
    height: 30px;
  }

  .gift-booking__quantity-button:hover{
    background-color: #ff9900;
  }
  
  .gift-booking__duration-label {
    width: 100%;
    text-align: center;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .gift-booking__quantity-input{
    border: none;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    border: black 2px solid;
    border-radius: 5px;
  }

  .gift-booking__card-selection{
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .gift-booking__card-option{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .gift-booking__card{
    margin: 30px 50px 20px;
  }

  .gift-booking__subtitle{
    text-align: center;
  }
  .gift-booking__card-option-radio{
    cursor: pointer;
  }

  .gift-booking__card-image{
    height: 250px;
    cursor: pointer;
  }
  
  .gift-booking__card-image:hover{
    opacity: 0.8;
  }

  .time__confirmation-text_subtitle{
    font-weight: bold;
    font-size: 24px;
  }
  .gift-booking__image{
    width: 350px;
    height: 300px;
    margin: 0 auto;
  }

  .gift-booking__error-message{
    position: absolute; 
    top: -16px;
  }

  @media screen and (max-width: 1200px) {
  .gift-booking__reservation{
    width: 80%;
  }
  .gift-booking__quantity-input{
    min-height: 20px;
  }
  }
  @media screen and (max-width: 1023px) {
  .gift-booking__form-field-pets-desktop{
    display: none;
  }
  .gift-booking__form-field-pets-mobile{
    display: block;
  }
  .gift-booking__next-step-button{
    margin: 0 auto;
  }
    .gift-booking__form{
        width: 80%;
    }

    .gift-booking__form-group input {
        min-height: 30px;
        padding: 4px 5px;
    } 

    .gift-booking__reservation{
      flex-direction: column;
    }

    .gift-booking__button-container{
      margin: 20px auto 0;
    }
    .gift-booking__quantity-input{
      min-height: 30px;
    }
    .gift-booking__duration-row{
      margin: 20px auto;
    }
    .gift-booking__quantity-buttons{
      gap: 5px;
    }
    .gift-booking__card-selection{
      flex-direction: column;
    }
}


@media screen and (max-width: 476px) {
  .gift-booking__form-error{
    margin-top: 2px;
    font-size: 14px;
  }
  .gift-booking__image{
    width: 310px;
    height: 300px;
    margin: 0 auto;
  }
  .gift-booking__session-container{
    width: 100%;
  }
  .gift-booking__quantity-input{
    width: 200px;
  }
  .gift-booking__quantity-button{
    width: 40px;
    height: 40px;
  }
}

@media screen and (max-width: 375px) {
  .gift-booking__form-group label{
    font-size: 12px;
  }

  .gift-booking__form-error{
    margin-top: 2px;
    font-size: 12px;
  }
}
@media screen and (max-width: 375px) {
.gift-booking__quantity-input{
  width: 180px;
}
.gift-booking__quantity-button{
  width: 30px;
  height: 30px;
}
}