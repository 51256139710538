  /* Navigation Styling */
  .time__navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    margin: 0 auto;
  }

    /* Step Container */
    .time__step {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 80%;
        margin: 0 auto;
        position: relative;
      }
  
        /* Point Styling */
  .time__point {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #918e8e;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: relative;
  }
  
  .time__point_active {
    background-color: #F6C852;
  }
  

/* Number inside the Dot */
.time__number {
    color: #fff;
    font-size: 1.2rem;
    font-weight: bold;
}

  /* Label Styling */
  .time__label {
    margin-top: 5px;
    font-family: EuropaGroteskNo2SHRoman;
    font-size: 1rem;
    color: #918e8e;
    position: absolute;
    top: 50px;
    white-space: nowrap;
    transition: color 0.3s ease;
  }
  
  .time__label_active {
    color: #F6C852;
  }

    /* Line Styling */
   .time__line {
    height: 2px;
    width: 150px;
    background-color: #918e8e;
    transition: background-color 0.3s ease;
    }
      
    .time__line_active {
        background-color: #F6C852;
    }
      

      @media screen and (max-width: 767px) {   
    .time__line{
        width: 70px
    } 
    .time__step{
        width: 95%;
      } 

    .time__point {
        width: 30px;
        height: 30px;
      }

    .time__number{
      font-size: 14px;
    }

    }

    @media screen and (max-width: 424px) {
        .time__line{
          width: 60px
        }
      }