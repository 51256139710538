.react-calendar{
  line-height: 1em;
}

.react-calendar__navigation{
  margin-bottom: 0.2em;
}
  /* Change the background color for active tiles on hover and focus */
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background-color: #F6C852; /* Your desired color */
    color: #fff; /* Optional: Change text color for better contrast */
}


/* Styling for Today’s Date */
.react-calendar__tile--now {
  background-color: #ffeb3b; /* Light yellow background */
  color: #918E8E; /* Dark text color */
  font-weight: bold;
  font-family: EuropaGroteskNo2SHRoman;
}

.react-calendar__tile{
  color: black;
  font-family: EuropaGroteskNo2SHRoman;
}

/* Styling for Past Days */
.react-calendar__tile--past-day {
  background-color: #f0f0f0; /* Light gray for past days */
  color: darkgray; /* Dimmed text */
  cursor: not-allowed; /* Not allowed cursor */
}

.react-calendar__month-view__days__day--weekend{
  color: red;
}

/* Styling for Neighboring Month Days */
.react-calendar__month-view__days__day--neighboringMonth {
  color: #ccc; /* Dimmed text for neighboring month days */
  background-color: #fafafa;
}


/* Custom styles for the active date tile */
.react-calendar__tile--active {
  background-color: #F6C852; /* Change to your desired background color */
  color: black; /* Change to your desired text color */
  font-weight: bold; /* Optional: Emphasize the selected date */
}

/* Style for the entire month and year container */
.react-calendar__navigation__label {
  font-size: 16px;         /* Increase font size */
  color: #2B2B2B;          /* Change text color */
  font-weight: bold;       /* Make text bold */
  font-family: EuropaGroteskNo2SHRoman;
}

  .react-calendar__navigation__arrow{
    color: #2B2B2B;   
  }
  .react-calendar__navigation__next-button{
    color: #2B2B2B; 
  }
  .react-calendar__navigation__next2-button{
    color: #2B2B2B; 
  }

  /* Container for Duration Buttons, Calendar, and Time Slots */
  .time__container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    margin-top: 50px;
  }
  

  /* Calendar Container */
  .time__calendar {
    position: relative;
    /* flex: 1; */
    max-width: 350px;
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .react-calendar{
    width: 100%;
  }

  /* Time Slots Container */
  .time__slots {
    flex: 1;
    width: 550px;
    margin: 0 auto;
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
  }
  
  .time__slots h2 {
    font-size: 18px;
    margin-bottom: 15px;
    color: #2B2B2B;
    margin: 0;
  }
  
  .time__slot {
    padding: 5px 5px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s ease;
    text-align: center;
  }
  
  .time__slot:hover {
    background-color: #e5e5e5; /* Light grey background on hover */
    color: #333; /* Darker text color for better contrast on hover */
    border-color: #333; /* Darker border to match the text color */
}

  /* Hover and Selected States */
  /* .time__slot:hover {
    background: #f1f1f1;
  }
   */
  .time__slot_selected {
    background: #F6C852;
    color: black;
    border-color: #F6C852;
  }

    /* List of Time Slots */
   .time__list {
    display: grid;
    position: relative;
    grid-template-columns: repeat(8, 1fr);
    gap: 10px;
    list-style-type: none;
    padding: 0;
  }

  .time__error-message-date{
    position: absolute;
    color: red;
    font-size: 16px;
    top: -35px;
    left: 25%;
    align-self: center;
    text-wrap: wrap;
  }
  
  .time__error-message-duration{
      position: absolute;
      color: red;
      left: 25%;
      top: -55px;
      /* width: 120px; */
      height: 20px;
      text-wrap: wrap;
    }

    .time__error-message-time{
      position: absolute;
      color: red;
      font-size: 16px;
      top: -60px;
      left: 35%;
      align-self: center;
      text-wrap: wrap;
    }

      /* Duration Buttons Styling */
    .time__duration-buttons {
      display: flex;
      flex-direction: row;
      /* grid-template-columns: 1fr 1fr;  /* Create two equal-width columns */
      gap: 20px;                    
      margin: 100px auto 10px;
    }


    .time__duration-button {
      width: 80px; /* Set width to 80px to create square buttons */
      height: 80px; /* Set height to 80px to make the button square */
      font-size: 16px;
      background-color: white; /* Transparent background for default state */
      color: black; /* Black text for default state */
      border: 2px solid black; /* Black border */
      border-radius: 5px; /* Slightly rounded corners */
      cursor: pointer;
      transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
      display: flex;
      justify-content: center;
      align-items: center; /* Center text inside the square */
      font-family: inherit;
      font-weight: bold;
      margin: 0 auto;
    }

    .time__duration-button:hover {
      background-color: #e5e5e5; /* Light grey background on hover */
      color: #333; /* Darker text color for better contrast on hover */
      border-color: #333; /* Darker border to match the text color */
  }
  
    
    .time__duration-button_active {
      background-color: #F6C852; /* Orange background when active */
      color: black; /* White text color when active */
      border-color: #F6C852; /* Border color matches background */
    }

    .time__slot_booked{
      background-color: #2B2B2B;
      color: white;
      cursor:unset;
    }

    .time__block{
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 400px;
      padding: 0 10px;
      /* border: #2B2B2B 1px solid ; */
      background-color: #f0f0f0;
      border-radius: 15px;
    }

    .time__subtitle{
      text-align: center;
    }

    @media screen and (max-width: 1200px) {
    .time__slots{
      width: 400px;
     }
    /* List of Time Slots */
    .time__list {
      grid-template-columns: repeat(6, 1fr);
      gap: 5px;
    }
      /* Duration Buttons Styling */
      .time__duration-buttons {
        display: flex;
        flex-direction: row;
        /* grid-template-columns: 1fr 1fr;  /* Create two equal-width columns */
        gap: 10px;                    
        margin: 100px auto 10px;
      }

    .time__duration-button {
      width: 60px; /* Set width to 80px to create square buttons */
      height: 60px; /* Set height to 80px to make the button square */
    }
      /* Calendar Container */
  .time__calendar {
      padding: 8px;
    }
    .time__slots{
      padding: 5px;
    }

    }

@media screen and (max-width: 1023px) {
    /* Container for Duration Buttons, Calendar, and Time Slots */
    .time__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 30px;
      margin-top: 50px;
    }

    .time__duration-buttons{
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 10px auto;
    }

    .time__duration-button {
      width: 80px; /* Set width to 80px to create square buttons */
      height: 80px; /* Set height to 80px to make the button square */
    }
    .time__block{
      height: 100%;
      padding: 20px;
      width: 400px;
    }

    .time__error-message-duration{
      top: -55px;
      left: 0%;
    }
    .time__error-message-time{
      left: 28%;
    }
  }

@media screen and (max-width: 767px) {
  /* .time__container {
    flex-direction: row;
  } */
  .time__slots-container{
    margin: 0 auto;
  }
  
  .time__slots{
    max-width: 350px;
  }

  .time__slot{
    padding: 5px 10px;
  }

  .time__list{
    grid-template-columns: repeat(4, 1fr);
  }

  .time__error-message{
    left: 25%;
   }

  .time__duration-buttons{
    margin-top: 20px; 
  }
  .time__duration-button {
    width: 60px;
    height: 50px;
  }
  .time__block{
    width: 350px;
  }
  .time__error-message-duration{
    left: -40px;
    top: -40px;
    width: 240px;
   }
}


@media screen and (max-width: 424px) {
  .time__block{
    width: 300px;
    padding:10px;
  }
  .time__duration-buttons{
    margin: auto;
  }

  .time__slots {
    width:270px;
  }
  .time__error-message-time{
    left: 20%;
  }
}
@media screen and (max-width: 374px) {
  .time__duration-button {
    width: 50px;
    height: 40px;
  }
   
  .time__error-message{
    left: 22%;
   } 
   .time__block{
    width: 270px;
  }
  .time__slots {
    width:250px;
  }
}
