.session{
    height: 80vh;
    width: 90%;
    margin:10vh auto;
}


.session__title{
    font-size: 32px;
    /* white-space: pre-wrap; */
}

.session__subtitle{
    font-size: 24px;
    /* white-space: pre-wrap; */
}

.session__container{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.session__card{
    width: 20%;
    background-color: #2B2B2B;
    padding: 0 10px;
    border-radius: 10px;
    height: 370px;
}

.session__card-title{
    color: white;
    text-align: center;
}

.session__card-image{
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.session__card-description{
    color: white;
    font-size: 16px;
}

@media screen and (max-width: 1200px) {
    .session{
        height: 100%;
        width: 90%;
        margin: 70px auto;
    }
    .session__container{
        justify-content: space-between;
        display: flex;
    }

    .session__card{
        width: 200px;
        height: 320px;
    }   

    .session__card-title{
        margin-top: 10px;
        margin-bottom: 8px;
    }

    .session__card-image{
        height: 150px;
    }

    .session__card-description{
        color: white;
        font-size: 14px;
    }
}

@media screen and (max-width: 1023px) {
    .session{
    height: 100%;
    }

    .session__container{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin-top: 50px;
    }

    .session__card{
        width: 250px;
        background-color: #2B2B2B;
        padding: 0 10px;
        border-radius: 10px;
        height: 350px;
        margin: 0 30px 40px;
    }

    .session__card-image{
        width: 100%;
        height: 200px;
        object-fit: cover;
    }
    
    .session__container{
       flex-wrap: wrap;    
    }
}

@media screen and (max-width: 767px) {
    .session__card{
        margin: 0 0 40px;
    } 
    .session__title{
        text-align: center;
    }
    .session__subtitle{
        white-space: pre-wrap;
        text-align: center;
    }
}

@media screen and (max-width: 630px) {
    .session__card{
        margin: 0 50px 40px;
    } 
}
@media screen and (max-width: 424px) {
    .session__card{
        margin: 0 0 40px;
    } 
}