.not-found{
    height: calc(100vh - 65px);
    padding-top: 65px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url(../../images/404.jpg);
    background-size: cover;
}

.not-found__title{
    margin-top: 250px;
    font-size: 120px;
    color: black;
    white-space: pre-wrap;
    text-align: center;
}

.not-found__text{
    color: black;
}

.not-found__button{
    border: none;
    cursor: pointer;
    background-color: white;
    font-family: inherit;
    border-radius: 30px;
    font-size: 16px;
    min-width: 200px;
    width: 100%;
    height: 40px;
    margin-bottom: 300px;
    font-weight: bold;
    color: black;
    border:black 2px solid;
    background-color: inherit;
    text-align: center;
}

.not-found__text{
    margin: 0;
}

.not-found__button:hover{
    background-color: #ff9900;
    color: white;
    border: none;   
}

@media screen and (max-width: 767px) {   
    .not-found{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-image: url(../../images/404.jpg);
        background-size: cover;
    }
    
    .not-found__title{
        margin-top: 120px;
        font-size: 65px;    
    }
    .not-found__button{
        height: 40px;
        margin-bottom: 300px;
    }
}