.intro {
    display: flex;
    flex-direction: center;
    margin-top: 65px;
    height: calc(100vh - 65px);
    background-image: url(../../images/intro/main_single.webp);
    background-size: 80% 100%;
    background-repeat: no-repeat; /* Ensures the image doesn't repeat */
    background-position: center; /* Centers the image within the container */
    background-color: black;
}

.intro__container{
    display:flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    width: 50%;
}

.intro__title{
    white-space: pre-wrap;
    color: white;
}
.intro__list{
    padding: 0;
    margin: 0;
}

.intro__subtitle{
    color: white;
    list-style: none;
    margin: 8px auto;
    text-align: start;

    font-size: 1.1em ;
}

.intro__buttons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 320px;
    margin-top: 60px;
}
.intro__picture{
    display: none;
}



@media screen and (max-width: 1025px) {
    .intro {
    min-height: 616px;
    height: auto;
    background-size: 100% 80%;
   }
}

@media screen and (max-width: 768px) {
    .intro {
    min-height: 616px;
    height: auto;
    /* background-image: url(../../images/intro/webp-test-mobile_.webp); */
    background-size: 90% 90%;
    background-position-y: 20px;
   }

   .intro__text {
    padding-top: 0px;
    margin-left: 10%;
   }
   .intro__title{
    margin-top: 0;
    text-align: start;
   }
   .intro__subtitle{
    text-align: start;
   }

    .intro__buttons{
    flex-direction: column;
    align-items: center;
    margin-top: 200px;
    width: 100%; 
    height: 100px;
    }

    .intro__container{
    display:flex;
    flex-direction: column; 
    justify-content: flex-start;
    margin-top: 35px;
    align-items: center;
    text-align: center;
    width: 100%;
    }
    
    /* .intro__subtitle{
    text-align: center;
    } */
}

@media screen and (max-width: 800px) {
    .intro {
    background-size: 90% 70%;
    background-image: none;
    position: relative;
    padding-bottom: 30px;
   }
   .intro__text{
   width: 88%;
   }
   .intro__mobile-container {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

   .intro__picture{
    display: block;
    width: 90%;
    /* height: 300px; */
    object-fit: contain;
    margin-top: 20px;
    margin-bottom: 20px;
}

   .intro__title {
    background-size: 90% 70%;
   }

   .intro__buttons{
    /* margin-top: 230px; */
    margin-top: 0;
    max-width: 150px;
    }
    
    .intro__container{
    min-height: 600px;
    }

    .intro__subtitle{
    text-align: start;
    }

    .intro__text {
    /* position: absolute;
    top: 460px; */
    left: 10%;
    margin-left: 0;
    }
}


/* @media screen and (max-width: 376px) {
    .intro {
    background-size: 100% 60%;
   }
   .intro__title {
     margin-bottom: 30px
   }
   .intro__container{
    margin-top: 300px;
    min-height: 600px;
    }
   .intro__text {
    position: absolute;
    top: 15%;
    left: 10%;
    margin-left: 0;
   }
   .intro__buttons{
    position: absolute;
    top: 550px;
    right: 10%;
    width: 50%;
    margin-top: 0;
   }
   .intro__subtitle{
    margin: 10px auto;
   }
}
*/

@media screen and (max-width: 380px) {
    .intro__title {
        margin-bottom: 20px;
        font-size: 20px
      }

    .intro__text {
        margin-left: 0px;
    }
} 