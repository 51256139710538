.admin{
    padding-top: 65px;
    height: calc(100% - 65px)
}

/* AdminRegister.css */

/* Overall Container */
.admin-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full height */
    background: #f5f5f5; /* Light background */
  }
  
  /* Form Container */
  .admin-form {
    width: 350px;
    padding: 20px;
    background: #ffffff; /* White background */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow */
  }
  
  /* Heading */
  .admin-form h2 {
    text-align: center;
    color: #333333; /* Darker text */
    margin-bottom: 20px;
  }
  
  /* Input Fields */
  .admin-form label {
    display: block;
    margin: 10px 0 5px;
    color: #555555; /* Slightly lighter text */
    font-weight: 500;
  }
  
  .admin-form input {
    width: calc(100% - 20px);
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #dddddd; /* Border for inputs */
    border-radius: 5px;
    outline: none;
    box-sizing: border-box;
  }
  
  /* Input Focus State */
  .admin-form input:focus {
    border: 1px solid #007bff; /* Blue border on focus */
  }
  
  /* Submit Button */
  .admin-form button {
    width: 100%;
    padding: 12px;
    background: #007bff; /* Primary button color */
    color: #ffffff; /* White text */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    transition: background 0.3s ease;
  }
  
  .admin-form button:hover {
    background: #0056b3; /* Darker shade on hover */
  }
  
  /* Error and Success Messages */
  .admin-form p {
    margin: 10px 0;
    font-size: 14px;
  }
  
  .admin-form p.error {
    color: #ff4d4d; /* Red for error messages */
  }
  
  .admin-form p.success {
    color: #28a745; /* Green for success messages */
  }
  