.about-payment{
    height: 40vh;
    width: 90%;
    margin: 10vh auto 30vh;
}

.about-payment__subtitle{
    width: 90%;
    margin-bottom: 50px;
    font-size: 24px;
}

.about-payment__container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.about-payment__card{
    width: 20%;
    padding: 0 10px;
    /* border-radius: 10px; */
    border-bottom: 3px solid black;
}

.about-payment__block{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.about-payment__block-number{
    color: black;
    font-size: 90px;
    width: 30%;
    margin: 0;
}

.about-payment__block-title{
    color: black;
    font-size: 24px;
    width: 70%;
    white-space: pre-wrap;
    margin: 0;
}

.about-payment__description{
    color: #333;
}


@media screen and (max-width: 1200px) {
    .about-payment{
        height: 100%;
        width: 90%;
        margin: 70px auto;
    }

    .about-payment__description{
        font-size: 14px;    
    }
}

@media screen and (max-width: 1023px) {
    .about-payment{
        min-height: 400px;
        margin: 30px auto;
    }
    .about-payment__block-number{
        color: black;
        font-size: 68px;
        width: 30%;
        margin: 0;
    }

    .about-payment__block-title{
        color: black;
        font-size: 18px;
    }
    .about-payment__description{
        font-size: 12px;    
    }
}

@media screen and (max-width: 767px) {
    .about-payment__card{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 90%;
        margin: 0 5%;
        height: 150px;
        padding: 0;
    }
    
    .about-payment__block{
        width: 30%;
    }
    
    .about-payment__block-number{

    }
    
    .about-payment__block-title{

    }

    .about-payment__container{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .about-payment__description{
        width: 70%;
        font-size: 14px;    
    }
}
@media screen and (max-width: 500px) {
    .about-payment__subtitle{
        margin: 0 auto;
        text-align: center;
        /* white-space: pre-wrap; */
    }
    .about-payment__block-number{
        font-size: 60px;
        margin-right: 5px;
    }
    
    .about-payment__block-title{
        font-size: 16px
    }

    .about-payment__description{
        width: 60%;
        font-size: 12px;    
    }
    
    .about-payment__card{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: 0;
        height: 130px;
    }
}