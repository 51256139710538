.time {
    width: 90%;
    margin: 0 auto;
    padding-top: 65px;
    min-height: calc(100vh - 65px);
    margin-bottom: 40px;
  }
  
  .time__title {
    font-size: 22px;
    color: #918E8E;
    margin: 3px 1px;
  }
  
  /* Number inside the Dot */
  .time__number {
    color: #fff;
    font-size: 1.2rem;
    font-family: inherit;
    font-weight: bold;
  }
    
  /* Minutes Container Styling */
  .time__minutes {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 250px;
  }
  
  .time__session {
    white-space: pre-wrap;
    color: black;
    font-weight: bold;
    font-size: 32px;
    border: 2px solid black;
    border-radius: 10px;
    padding: 8px;
  }
  
  .time__button-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 80%;
    margin: 0 auto;
  }
  
  /* Next Step Button */
  .time__next-step {
    width: 90%;
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    margin-right: 0px;
  }

  .time__final-step-button{
    font-family: EuropaGroteskNo2SHRoman;
    width: 250px;
    height: 40px;
    border: 0;
    background-color: #F6C852;
    color: white;
    font-size: 18px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .time__final-step-button_active {
    background-color: #F6C852;
    color: #fff;
    cursor: pointer;
  }
  
  .time__final-step-button_active:hover {
    background-color: #e68900;
    color: white;
  }
  
  .time__next-step-button {
    font-family: EuropaGroteskNo2SHRoman;
    width: 120px;
    height: 40px;
    margin-top: 20px;
    margin-right: 0;
    padding: 10px 20px;
    font-size: 18px;
    background-color: #918E8E;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: not-allowed;
    transition: background-color 0.3s ease;
  }
  

  .time__next-step-button_active {
    background-color: #F6C852;
    color: white;
    cursor: pointer;
  }
  
  .time__next-step-button_active:hover {
    background-color: #e68900;
    color: white;
  }
  
  /* Confirmation Layer Styling */
  .time__confirmation {
    display: grid; /* Use CSS Grid */
    grid-template-columns: 1fr 1fr 1fr; /* Three equal columns */
    align-items: center; /* Vertically align elements */
    width: 80%;
    margin: 30px auto 0;
  }

  .time__confirmation-subtitle{
    text-align: center;
    margin-bottom: 0;
  }

  .time__confirmation-final{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .time__confirmation-text {
    font-size: 1.2rem;
    color: #333;
    margin: 10px auto;
    text-align: center;
  }
  
  .time__back-button-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 900%;
    margin: 40px auto 0;

  }

  .time__back-button {
    width: 120px;
    height: 40px;
    margin-top: 20px;
    font-size: 18px;
    padding: 10px 20px;
    font-family: EuropaGroteskNo2SHRoman;
    border: 1px solid #918E8E;
    border-radius: 5px;
    background-color: #2B2B2B;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .time__back-button:hover {
    background-color: #f1f1f1;
    color: black;
  }
  

@media screen and (max-width: 1200px) {
  .time__confirmation-text{
    font-size: 16px;
  }

.time__confirmation{
  display: flex;
  flex-direction: column;
}

.time__back-button{
  align-self: flex-start;
}
}


@media screen and (max-width: 1023px) {
  .time{
    height: 100%;
    margin-bottom: 30px;
  }

  .time__back-button{
    width: 80px;
    font-size: 16px;
  }
  .time__next-step{
    justify-content: center;
    margin: 30px auto 0;
  }

  .time__next-step-button {
    width: 240px;
  }

  .time__button-container{
    justify-content: center;
  }
  
}

@media screen and (max-width: 767px) {
  .time__number{
    font-size: 14px;
}
  .time__confirmation{
    width: 100%;
  }

  .time__confirmation-text{
    margin-top: 14px;
    font-size: 16px;
  }
}