.carousel {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
  
  .carousel__slider {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%; /* Set a fixed height for the carousel */
    overflow: hidden;
  }
  
  .carousel__image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Make the image cover the entire container */
    object-position: center; /* Center the image in the container */
    opacity: 0.99;
    transition: transform 0.2s ease, opacity 0.2s ease;
  }
  
  .carousel__image_transition {
    transform: translateX(0%);
    opacity: 1;
  }

  .carousel__arrows {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 2;
    background-color: rgba(43, 43, 43, 0.4); /* Semi-transparent background */
    border-radius: 50%; /* Make the container circular */
    border: 1px solid rgba(255, 255, 255, 0.8); /* Semi-transparent white border */
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    transition: all 0.3s ease; /* Smooth transition for hover effects */
  }
  
  /* Hover effect for arrows */
  .carousel__arrows:hover {
    background-color: rgba(43, 43, 43, 0.9); /* Increase background opacity */
    border-color: rgba(255, 255, 255, 1); /* Change border to fully opaque */
    transform: translateY(-50%) scale(1.1); /* Scale up slightly */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* Add subtle shadow */
  }
  
  .carousel__arrow_left::before,
  .carousel__arrow_left::after,
  .carousel__arrow_right::before,
  .carousel__arrow_right::after {
    content: "";
    position: absolute;
    display: block;
    width: 10px; /* Length of the arrow lines */
    height: 2px; /* Thickness of the lines */
    background-color: white;
  }
  
  /* Left Arrow - Adjust positioning to form a directional arrow */
  .carousel__arrow_right::before {
    transform: rotate(45deg); /* Top part of the left arrow */
    top: 11px;
    left: 10px;
  }
  
  .carousel__arrow_right::after {
    transform: rotate(-45deg); /* Bottom part of the left arrow */
    bottom: 11px;
    left: 10px;
  }
  
  /* Right Arrow - Adjust positioning to form a directional arrow */
  .carousel__arrow_left::before {
    transform: rotate(-45deg); /* Top part of the right arrow */
    top: 11px;
    right: 10px;
  }
  
  .carousel__arrow_left::after {
    transform: rotate(45deg); /* Bottom part of the right arrow */
    bottom: 11px;
    right: 10px;
  }
  
  /* Positioning of the arrows */
  .carousel__arrow_left {
    left: 10px;
  }
  
  .carousel__arrow_right {
    right: 10px;
  }
  
  /* Dots Navigation: Position as absolute within the image */
  .carousel__dots {
    position: absolute;
    bottom: 20px; /* Adjust this to control the vertical position */
    left: 50%;
    transform: translateX(-50%); /* Center the dots horizontally */
    display: flex;
    justify-content: center;
    z-index: 3; /* Make sure dots appear above the image */
  }
  
  .carousel__dot {
    height: 12px;
    width: 12px;
    margin: 0 5px;
    background-color: rgba(255, 255, 255, 0.7); /* Semi-transparent white */
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .carousel__dot_active {
    background-color: rgba(255, 255, 255, 1); /* Fully opaque white for the active dot */
  }
  