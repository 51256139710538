.photos__container{
    display: flex;
    flex-direction: row;
    height: 90vh
}

.photos__shoots{
    width: 50%;
}

.photos__image{
    width: 100%;
    height: 100%;
}

.photos__description{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: #2B2B2B;
    color: white;
    width: 50%;
}

.photos__subtitle{
    width: 70%;
    margin: 0 auto;
    text-align: center;
    font-size: 32px;
    color: white;
}

.photos__text{
    width: 60%;
    font-weight: bold;
    margin-bottom: 100px;
}

@media screen and (max-width: 767px) {
    .photos__container {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .photos__text{
        margin-bottom: 40px; 
        width: 80%;  
    }
  
    .photos__shoots {
      width: 100%;
      height: 400px;
      order: 1; /* Display after description */
    }
  
    .photos__description {
      width: 100%;
      order: 0; /* Display before the carousel */
      padding: 30px 0;
    }
  }
  