.faq {
    width: 80%;
    margin: 0 auto;
    padding: 40px 0;
    min-height: calc(100vh - 65px)
  }
  
  .faq__title {
    /* text-align: center; */
    font-size: 32px;
    margin-bottom: 20px;
    color: black;
  }
  
  .faq__container {
    margin-top: 20px;
  }
  
  .faq__item {
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .faq__question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background-color: #2B2B2B;
    padding: 15px;
    transition: background-color 0.3s ease;
  }
  
  .faq__question h2 {
    margin: 0;
    font-size: 1.2rem;
    color: white;
  }
  
  .faq__question:hover {
    background-color: #918E8E;
  }
  
  .faq__icon {
    font-size: 1.5rem;
    transition: transform 0.3s ease;
    color: white;
  }
  
  .faq__icon_open {
    transform: rotate(180deg); /* Rotate the icon when the answer is visible */
  }
  
  .faq__answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
    padding: 0 15px;
  }
  
  .faq__answer_visible {
    max-height: 150px; /* Set a max-height to show the answer */
    padding: 15px; /* Add padding when visible */
  }
  
  .faq__answer p {
    margin: 0;
    font-size: 1rem;
    color: black;
  }

  @media screen and (max-width: 1200px) {
    .faq{
        height: 100%;
        width: 90%;
    }
  }
  