/* Cookie Consent Banner */
.cookie-consent-banner {
  position: fixed;
  bottom: 0; /* Fix it at the bottom */
  left: 0;
  right: 0;
  background-color: #333;
  color: white;
  padding: 20px;
  text-align: center;
  z-index: 1000; /* Lower than the header */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Ensure paragraph stays inline with buttons */
.cookie-consent-banner p {
  margin: 0;
}

/* Buttons wrapper to control spacing */
.cookie-buttons {
  display: inline-flex;
}

/* Style for the buttons */
.cookie-button {
  background-color: white;
  color:#333;
  font-weight: bold;
  border: none;
  padding: 10px 20px;
  margin-left: 10px;
  cursor: pointer;
}

.cookie-button:hover {
  background-color: #0056b3;
}

.cookie__link{
  color: white;
}

/* Ensure responsiveness for small screens */
@media (max-width: 600px) {
  .cookie-consent-banner {
    flex-direction: column;
    text-align: center;
    padding: 15px;
  }

  .cookie-buttons {
    margin-top: 10px;
    width: 90%;
    justify-content: space-between;
  }

  .cookie-button{
    width: 150px;
    margin-left: 0;
    margin-top: 5px;
  }
}

/* Add margin to the bottom of the main content to prevent overlap with the cookie banner */
.main-content {
  margin-bottom: 80px; /* Adjust this value based on the height of the cookie banner */
}

@media (max-width: 320px) {
  .cookie-button{
  width: 120px;
  }
}