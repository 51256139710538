/* PaymentForm.css */
.payment{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin:0;
    width: 100%;
    height: 100%;
    padding-top: 65px;
    min-height: calc(100vh - 65px);
}
/* PaymentForm.css */

/* PaymentForm.css */

.payment-container {
    width: 70%;
    max-width: 1000px;
    margin: 20px auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
  }
  
  .payment-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* width: 700px; */
  }
  
  .payment-details {
    flex: 1;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    margin-right: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .payment-form {
    flex: 1;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .StripeElement {
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .payment-row {
    display: flex;
    justify-content: space-between;
  }
  
  .payment-field {
    flex: 1;
    margin-right: 10px;
  }
  
  .payment-label {
    font-weight: bold;
  }
  
  .payment-button {
    width: 100%;
    padding: 12px;
    margin-top: 20px;
    border: none;
    background: #007bff;
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .payment-button:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
  
  .error-message {
    color: red;
    margin-top: 10px;
  }
  
  

  .payment-footer {
    margin-top: 20px;
    text-align: center;
  }
  
  .payment-footer p {
    font-size: 0.9rem;
  }
  
  .payment-footer .terms {
    margin-top: 10px;
  }
  
  .payment-footer .terms a {
    font-size: 0.8rem;
    margin: 0 5px;
    color: #333;
    text-decoration: none;
  }
  
  .payment-footer .terms a:hover {
    text-decoration: underline;
  }
  
  
@media screen and (max-width: 1023px) {  
  .payment-container {
    width: 80%;
  }
}

@media screen and (max-width: 727px) { 
  .payment-container {
    width: 60%;
    margin: 40px auto 80px; 
  } 
  .payment-content {
    display: flex;
    flex-direction: column;
  }

  .payment-details{
    margin-bottom: 60px;
    margin-right: 0;
    padding: 10px;
  }

  .payment-details p{
    margin: 5px auto
  } 

  .payment-form{
    padding: 10px; 
  }
}
@media screen and (max-width: 424px) {
  .payment-container {
    width: 80%;
  } 
}