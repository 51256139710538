.button{
  display: flex;
  justify-content: center;
  align-items: center;
  height:40px;
  width: 150px;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  align-content: center;
  text-align: center;
}

.button__book-time{
   background-color: #F6C852;
   color: white;
   transition: background-color 0.3s ease; /* Added box-shadow transition */
}

.button__book-time:hover {
  background-color: #FF9900;
}

.button__book-gift {
  background-color: white;
  color: black;
  cursor: pointer;
  transition: box-shadow 0.3s ease; /* Added box-shadow transition */
}

/* .button__book-gift:hover {
  background-color: #2B2B2B;
  color: white;
  box-shadow: inset 0 0 0 2px #ff9900; /* Inner border effect */
/* } */ 

.button__book-gift {
  background-color: white;
  color: black;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
}

.button__book-gift:hover {
  background-color:  #2B2B2B;
  box-shadow: inset 0 0 0 2px white;
  color: white; /* Change text color on hover */
}

