  /* Coupon Container */
  .time__coupon {
    display: flex;
    position: relative;
    align-items: center;
    gap: 10px;
    max-width: 400px;
    margin-top: 0;
    margin-bottom: 50px;
  }

/* Coupon Input Field */
.time__coupon-input {
  padding: 8px 10px;
  font-size: 16px;
  border: 1px solid #918E8E;
  border-radius: 4px;
  width: 200px;
  margin-right: 10px;
}

/* Coupon Apply Button */
.time__coupon-button {
  font-family: EuropaGroteskNo2SHRoman;
  padding: 8px 12px;
  font-size: 16px;
  border: 1px solid #918E8E;
  border-radius: 5px;
  background-color: #2B2B2B;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.time__coupon-button:hover {
  background-color: #f1f1f1;
  color: black;
}

.time__timer {
  font-size: 18px;
  color: black;
  margin: 20px 0;
}

.time__error-message{
  position: absolute;
  color: red;
  margin: 0;
  top:120%;
  left:0;
}

.time__success-message{
  position: absolute;
  color: green;
  margin: 0;
  top:120%;
  left:0;
}

.time__remaining-balance{
  position: absolute;
  color: black;
  margin: 0;
  top:120%;
  width: 100%;
  left:0;
}

.time__enter-text{
text-align: center;
color:#2B2B2B;
font-size: 18px;
font-weight: bold;
margin-bottom: 0;
}

@media screen and (max-width: 424px) {
    .time__coupon-button{
        padding: 3px 12px;
        min-height: 40px;
        font-size: 14px;
      }
    
    .time__coupon-input {
        font-size: 14px;
        padding: 10px 10px;
        margin-right: 0;
    }
    .time__remaining-balance{
      font-size: 14px;
      width: 300px;
    }
}

@media screen and (max-width: 374px) {
  .time__coupon-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 140px
  }

    .time__coupon-input {
      width: 180px;
    }
  }