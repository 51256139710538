.privacy{
    height: calc(100vh - 65px);
    width: 80%;
    margin: 80px auto 0;
}

.privacy__title{
    font-size: 32px;
}

.privacy__subtitle{
    font-size: 24px;
}


.privacy__container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.privacy__card{
    width: 20%;
    border: 2px black solid;
    padding: 0 10px;
    border-radius: 10px;
    height: 370px;
}

.privacy__card-title{
    color: black;
    text-align: center;
}

.privacy__card-image{
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.privacy__card-description{
    color: black;
}

@media screen and (max-width: 1200px) {
    .privacy{
        height: 100%;
        width: 90%;
        margin: 70px auto;
    }
    .privacy__container{
        justify-content: space-between;
        display: flex;
    }

    .privacy__card{
        width: 200px;
        height: 320px;
    }

    .privacy__card-title{
        margin-top: 10px;
        margin-bottom: 8px;
    }

    .privacy__card-image{
        height: 150px;
    }

    .privacy__card-description{
        font-size: 14px;
    }
}

@media screen and (max-width: 1023px) {
    .privacy{
    height: 100%;
    }

    .privacy__container{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin-top: 50px;
    }

    .privacy__card{
        width: 250px;
        background-color: white;
        padding: 0 10px;
        border-radius: 10px;
        height: 350px;
        margin: 0 30px 40px;
    }

    .privacy__card-image{
        width: 100%;
        height: 200px;
        object-fit: cover;
    }
    
    .privacy__container{
       flex-wrap: wrap;    
    }
}

@media screen and (max-width: 767px) {
    .privacy__card{
        margin: 0 0 40px;
    } 
        
    .privacy__subtitle{
        text-align: center;
    }
}

@media screen and (max-width: 630px) {
    .privacy__card{
        margin: 0 50px 40px;
    } 
    
    .privacy__title{
        text-align: center;
    }
}
@media screen and (max-width: 424px) {
    .privacy__card{
        margin: 0 0 40px;
    } 
}