@import url(./vendor/fonts/fonts.css);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont,'EuropaGroteskNo2SHRoman', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    /* height: 100%;
    width: 100%;
    background-image: url(../src/images/coming-soon.png);
    background-size: cover; */
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
