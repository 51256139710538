.footer{
    background-color: black;
    display: flex;
    flex-direction: column;
}

.footer__info{
    display: flex;
    flex-direction:row;
    width: 80%; 
    margin: 0 auto;
    justify-content: space-between;
    align-items: flex-start;
    height: 140px;
    padding-top: 50px;
}

.footer__link{
    color: white;
    font-size: 14px;
    font-family: inherit;
    text-decoration: none;
    background-color: black;
    border: none;
    cursor: pointer;
    /* font-weight: bold; */
}

.footer__link:hover {
    color: #F6C852;
}


.footer__link_subtitle{
    margin-top: 5px;
}


.footer__subtitle{
    color: white;
    text-decoration: none;
    margin-top: 10px;
    font-weight: normal;
}

.footer__reg{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 0 auto;
    color: white;
    height: 80px;
    font-size: 14px;
    width: 90%;
}

.footer__reg-container{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 70%;
}

.footer__reg-link{
    color: white;
    text-decoration: none;
    margin-left: 30px;
    /* font-weight: bold; */
    white-space: pre-wrap;
}

.footer__reg-link:hover{
    color: #F6C852;
}

.footer__reg-logo{
 width: 35px;
 height: 35px;  
 cursor: pointer; 
}

.footer__reg-logo_x{
    margin-left: 17.5px;
}

.footer__reg-copyright{

}

@media screen and (max-width: 1000px) {
.footer__info{
 width: 90%;
}

.footer__reg{
    width: 90%;
}
} 

@media screen and (max-width: 450px) {
    .footer__info {
        height: 500px;
        padding: 0 5%;
        flex-direction:column;
        justify-content: space-evenly;
        align-items: flex-start;
    }

    .footer__reg {
        height: 100%;
        padding: 0 5%;
        flex-direction:column;
        justify-content: space-evenly;
        align-items: flex-start;
        margin: 0;
    }
    
    .footer__reg-container {
        flex-direction:column;
        justify-content: space-between;
        align-items: flex-start;
        width: 90%;
        margin: 0;
    }

    .footer__reg-link{
        margin-left: 0;
        margin-bottom: 10px;
        white-space:unset;
        padding: 1px 6px;
    }
    .footer__reg-logo_x{
        margin-left: 0;
        margin-bottom: 10px;
    }
}