.terms-of-use-container{
    padding-top: 65px;
}

/* Base styles */
.terms-of-use {
    max-width: 800px;
    margin: 50px auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .terms-of-use h1 {
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
  }
  
  .terms-of-use em {
    display: block;
    margin-bottom: 20px;
    text-align: center;
    font-size: 0.9rem;
    color: #777;
  }
  
  .terms-of-use h2 {
    font-size: 1.5rem;
    margin-top: 20px;
    color: #333;
  }
  
  .terms-of-use h3 {
    font-size: 1.3rem;
    margin-top: 15px;
    color: #555;
  }
  
  .terms-of-use p,
  .terms-of-use ul {
    margin-bottom: 20px;
    font-size: 1rem;
    color: #555;
  }
  
  .terms-of-use ul {
    padding-left: 20px;
  }
  
  .terms-of-use li {
    margin-bottom: 10px;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .terms-of-use {
      padding: 15px;
    }
  
    .terms-of-use h1 {
      font-size: 1.75rem;
    }
  
    .terms-of-use h2 {
      font-size: 1.25rem;
    }
  
    .terms-of-use p,
    .terms-of-use ul {
      font-size: 0.95rem;
    }
  }
  
  @media (max-width: 480px) {
    .terms-of-use {
      padding: 10px;
    }
  
    .terms-of-use h1 {
      font-size: 1.5rem;
    }
  
    .terms-of-use h2 {
      font-size: 1.1rem;
    }
  
    .terms-of-use p,
    .terms-of-use ul {
      font-size: 0.9rem;
    }
  }
  