/* Container for the entire comments */
.comments {
    position: relative;
    width: 80%;
    margin: 0 auto;
    min-height: calc(50vh - 65px);
  }
  
  /* Titles styling */
  .comments__title {
    text-align: start;
    margin-top: 80px;
    font-size: 2rem;
  }
  
  .comments__subtitle {
    text-align: start;
    margin-bottom: 20px;
    font-size: 1.2rem;
  }
  
  /* Slider: holds arrows and viewport */
  .comments__slider {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: hidden;
  }
  
  /* Viewport: hides overflow of the sliding container */
  .comments__viewport {
    overflow: hidden;
    width: 100%;
  }
  
  /* Container that slides left/right */
  .comments__container {
    display: flex;
  }
  
  /* Comment card styling */
  .comments__card {
    box-sizing: border-box;
    padding: 20px;
    text-align: center;
  }
  
  /* Circular image styling */
  .comments__image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin: 0 auto 10px;
    object-position: center;
  }
  .comments__card-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin: 0 auto 10px;
  }
  
  .comments__card-description{
    position: relative;
    font-style: italic;
    padding: 20px;
    margin: 20px 0;
    text-align: center;
  }

  .comments__card-description::before {
    content: "“";
    font-size: 3rem;
    color: #ccc;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .comments__card-description::after {
    content: "”";
    font-size: 3rem;
    color: #ccc;
    position: absolute;
    bottom: -10px;
    right: 0;
  }
  
  /* Author name */
  .comments__name {
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
  
  /* Review text */
  .comments__review {
    font-size: 1rem;
  }
  
  /* Navigation arrows styling (shared with Photoscomments) */
  .comments__arrows {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 2;
    background-color: rgba(43, 43, 43, 0.4);
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.8);
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
  }
  
  .comments__arrows:hover {
    background-color: rgba(43, 43, 43, 0.9);
    border-color: rgba(255, 255, 255, 1);
    transform: translateY(-50%) scale(1.1);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  }
  
  /* Create arrow shapes using pseudo-elements */
  .comments__arrow_left::before,
  .comments__arrow_left::after,
  .comments__arrow_right::before,
  .comments__arrow_right::after {
    content: "";
    position: absolute;
    display: block;
    width: 10px;
    height: 2px;
    background-color: white;
  }
  
  /* Left arrow (points to previous slide) */
  .comments__arrow_right::before {
    transform: rotate(45deg);
    top: 11px;
    left: 10px;
  }
  .comments__arrow_right::after {
    transform: rotate(-45deg);
    bottom: 11px;
    left: 10px;
  }
  
  /* Right arrow (points to next slide) */
  .comments__arrow_left::before {
    transform: rotate(-45deg);
    top: 11px;
    right: 10px;
  }
  .comments__arrow_left::after {
    transform: rotate(45deg);
    bottom: 11px;
    right: 10px;
  }
  
  /* Positioning of the arrows */
  .comments__arrow_left {
    left: 10px;
  }
  
  .comments__arrow_right {
    right: 10px;
  }
  
  /* Dots navigation styling */
  .comments__dots {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    z-index: 3;
  }
  
  .comments__dot {
    height: 12px;
    width: 12px;
    margin: 0 5px;
    background-color: rgba(6, 6, 6, 0.529);
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .comments__dot_active {
    background-color: rgb(0, 0, 0);
  }
  
  .comments__stars {
    margin: 8px 0;
  }
  
  .comments__star {
    font-size: 1.2rem;
    color: #ccc; /* Default empty star color */
    margin: 0 1px;
  }
  
  .comments__star--filled {
    color: gold; /* Filled star color */
  }
  