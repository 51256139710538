.privacy-policy-container {
padding-top: 65px;
}

/* Base styles */
.privacy-policy {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .privacy-policy h1 {
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
  }
  
  .privacy-policy em {
    display: block;
    margin-bottom: 20px;
    text-align: center;
    font-size: 0.9rem;
    color: #777;
  }
  
  .privacy-policy h2 {
    font-size: 1.5rem;
    margin-top: 20px;
    color: #333;
  }
  
  .privacy-policy p,
  .privacy-policy ul {
    margin-bottom: 20px;
    font-size: 1rem;
    color: #555;
  }
  
  .privacy-policy ul {
    padding-left: 20px;
  }
  
  .privacy-policy li {
    margin-bottom: 10px;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .privacy-policy__container {
      padding: 15px;
    }
  
    .privacy-policy h1 {
      font-size: 1.75rem;
    }
  
    .privacy-policy h2 {
      font-size: 1.25rem;
    }
  
    .privacy-policy p,
    .privacy-policy ul {
      font-size: 0.95rem;
    }
  }
  
  @media (max-width: 480px) {
    .privacy-policy__container {
      padding: 10px;
    }
  
    .privacy-policy h1 {
      font-size: 1.5rem;
    }
  
    .privacy-policy h2 {
      font-size: 1.1rem;
    }
  
    .privacy-policy p,
    .privacy-policy ul {
      font-size: 0.9rem;
    }
  }
  