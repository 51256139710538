.gifts{
    height: calc(100vh - 65px);
    background-color: #2B2B2B;
    width: 80%;
    padding: 0 10%;
}

.gifts__title{
    padding-top: 5vh;
    color: white;
    font-size: 32px;
}

.gifts__container{
    display: flex;
    flex-direction: row;
}

.gifts__digital{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
}

.gifts__physical{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
}

.gifts__subtitle{
    color: white;
    text-align: center;
}

.gifts__description{
    color: white;
    width: 50%;
    min-height: 60px;
}

.gifts__price{
    color: white
}

.gifts__image{
    width: 250px;
    height: 250px;
    margin-bottom: 30px;
    object-fit: contain;
}


@media screen and (max-width: 1200px) {
.gifts{
    height: 100%;
    /* width: 90%; */
    margin: 70px auto;
    padding-bottom: 30px;
}

.gifts__description{
    width: 70%;
    margin: 20px auto;
}
.gifts__image{
    width: 70%;
    height: 220px;
}
}

@media screen and (max-width: 800px) {
    .gifts{
        height: 100%;
        width: 80%;
        margin: 30px auto;
    }
    
    .gifts__title{
        text-align: center;
    }

    .gifts__container{
        flex-direction: column;
    }

    .gifts__digital{
        width: 100%;
        margin-bottom: 30px;
    }
    
    .gifts__physical{
        width: 100%;
        border-top: 2px solid white
    }
    
    .gifts__description{
        width: 60%;
        margin: 20px auto;
    }
    .gifts__image{
        width: 60%;
    }
    }
    
    @media screen and (max-width: 426px) {
        
    .gifts__image{
        width: 280px;
        height: 300px;
    }
}
